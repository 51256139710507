import React from "react"

import Header from "../header"
import Seo from "../seo"
import Footer from "../footer"

import { Main } from "./styles"

import Contact from "../contact"
import Privacity from "../privacity"

const Layout = ({ children, description, meta, page, bg, image, title }) => {

    return (
        <>
            <Seo
                description={description}
                meta={meta}
                title={title}
                image={image}
            />

            <Header className="fixed" page={page} />

            <Main bg={bg}>
                {children}

                {title === "Contato" && <Footer />}
            </Main>

            <Contact />
            <Privacity />
        </>
    )
}

export default Layout
